.loader {
  width: 100%;

  display: flex;
  flex-wrap: wrap;

  margin-left: 20px;
  margin-top: 15px;
}

@media (max-width: 1024px) {
  .loader {
    display: none;
  }
}
